import { render, staticRenderFns } from "./ModalProducts.vue?vue&type=template&id=1c6c4750&"
import script from "./ModalProducts.vue?vue&type=script&lang=js&"
export * from "./ModalProducts.vue?vue&type=script&lang=js&"
import style0 from "./ModalProducts.vue?vue&type=style&index=0&id=1c6c4750&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SlideShow: require('/var/www/pb-store/components/SlideShow.vue').default,AttributesCheckbox: require('/var/www/pb-store/components/AttributesCheckbox.vue').default})
