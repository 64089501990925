
export default {
  name: 'SlideShow',
  props: {
    images: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: 'Producto'
    }
  },
  // computed: {
  //   imgs () {
  //     return Array.from(this.images)
  //   }
  // },
  methods: {
    selectSlide (ind) {
      const slides = this.$el.querySelector('.slideShow .slides')
      slides.scrollLeft = slides.offsetWidth * ind
    }
  }
}
