
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AttributesCheckbox',
  props: {
    product: {
      type: Object,
      required: true
    },
    cartView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attributes: []
    }
  },
  computed: {
    ...mapGetters({
      getGroupedAttributes: 'getGroupedAttributes',
      getFeatureById: 'getFeatureById',
      checkProd: 'cart/checkProd'
    })
  },
  created () {
    this.attributes = this.checkProd(this.product).attributes
  },
  updated () {
    this.attributes = this.checkProd(this.product).attributes
  },
  methods: {
    ...mapActions({
      udpAttr: 'cart/udpAttr'
    }),
    updateAttributes (event) {
      const vm = this
      this.udpAttr({
        item: vm.product,
        attributes: this.attributes
      })
    }
  }
}
