
import { mapActions, mapGetters } from 'vuex'
import SlideShow from '~/components/SlideShow'
import AttributesCheckbox from '~/components/AttributesCheckbox'
export default {
  name: 'ModalProducts',
  components: {
    AttributesCheckbox,
    SlideShow
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      timeOut: null,
      check: []
    }
  },
  computed: {
    ...mapGetters({
      checkProd: 'cart/checkProd',
      prodPrice: 'cart/prodPrice',
      getRealPrice: 'getRealPrice',
      getFeatureById: 'getFeatureById',
      getGroupedAttributes: 'getGroupedAttributes'
    }),
    imgs () {
      if (Object.prototype.hasOwnProperty.call(this.product, 'images')) {
        if (!this.product.images) {
          return [this.product.image]
        } else if (Array.isArray(this.product.images)) {
          return this.product.images
        } else {
          return JSON.parse(this.product.images)
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      updateCart: 'cart/updateCart',
      removeItemInCart: 'cart/removeItemInCart'
    }),
    updateQuantity (event) {
      if (this.timeOut) { clearTimeout(this.timeOut) }
      this.timeOut = setTimeout(() => {
        const vm = this
        this.updateCart({
          item: vm.checkProd(vm.product),
          qty: parseInt(event.target.value),
          isAdd: false
        })
      }, 1200)
    },
    addItem () {
      const prod = {
        item: Object.assign({}, this.product),
        qty: 1,
        isAdd: true
      }
      this.updateCart(prod)
      this.$store.commit('SHOW_Cart', true)
      this.$notify.show({
        text: 'Product added',
        type: 'success',
        closeWait: 2000
      })
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
