
import ModalProducts from '~/components/ModalProducts'
import ProductsCard from '~/components/ProductsCard'
export default {
  name: 'CategorySlide',
  components: {
    ProductsCard,
    ModalProducts
  },
  // UPDATE STORE PRODUCTS
  async fetch () {
    await this.$store.dispatch('GET_WebsiteData')
  },
  data () {
    return {
      reload: true,
      slides: null,
      guides: null,
      scrollLeft: null
    }
  },
  // activated () {
  //   if (this.$fetchState.timestamp <= Date.now() - 180000) {
  //     this.$fetch()
  //   }
  // },
  mounted () {
    this.slides = this.$el.querySelector('.categorySlides .con-slides')
    // this.guides = this.$el.querySelector('.categorySlides .slides-guides')
    // this.slideCategories()
  },
  updated () {
    this.$fetch()
  },
  methods: {
    sortProducts (prods) {
      const productos = [...prods]
      productos.sort(function (a, b) {
        return a.price - b.price
      })
      return productos
    },
    showSlide (ind) {
      this.scrollLeft = ind * this.slides.offsetWidth
      this.slides.scrollLeft = this.scrollLeft
      // this.checkCurrentActiveSlide()
      this.reload = false
    },
    slideCategories () {
      const slides = this.slides
      setInterval(() => {
        if (this.reload) {
          this.scrollLeft = slides.scrollLeft === slides.scrollWidth - slides.offsetWidth ? 0 : slides.scrollLeft + slides.offsetWidth
          slides.scrollLeft = this.scrollLeft
          // this.checkCurrentActiveSlide()
        }
      }, 4000)
    },
    checkCurrentActiveSlide () {
      if (window.innerWidth < 500) {
        this.scrollLeft = this.slides.scrollLeft
      }
      const ind = Math.round(this.scrollLeft / this.slides.offsetWidth)
      Array.from(this.guides.children).forEach((elm) => {
        elm.classList.remove('active')
      })
      if (!isNaN(ind)) {
        this.guides.children[ind].classList.add('active')
      }
    }
  }
}
